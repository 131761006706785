import {Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";

export const MultipleSelectChip = ({field, elements = [], nameLabel = "Select Multiple"}) => {
    return (
        <>
            <InputLabel id="demo-multiple-chip-label">{nameLabel}</InputLabel>
            <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                {...field}
                input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
                renderValue={(selected) => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {selected.map(({name}) => (
                            <Chip key={name} label={name}/>
                        ))}
                    </Box>
                )}
            >
                {elements?.map((element) => (
                    <MenuItem
                        key={element.name}
                        value={element}
                    >
                        {element.name}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}
