import React, {useEffect, useState} from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import {Button, Checkbox, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import parseISO from "date-fns/parseISO";
import Sweet from "sweetalert2";
import {Download} from "@mui/icons-material";
import axios from "axios";
import {Controller, useForm} from "react-hook-form";
import Swal from "sweetalert2";

import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import url from "../../../../../config";
import Loading from "../../../../../examples/loading";
import {NumberFormatCurrencySystem} from "../../../../../examples/NumberFormat";
import MDInput from "../../../../../components/MDInput";
import UploadFile from "../../../../../examples/UploadFile";
import {ConfigHeaderBlobResponse, ConfigHeaderMultiPart} from "../../../../../utils/configHeader";
import SelectMultiple from "../../../../../examples/SelectMultiple";


const CandidateRegisterComponent = () => {
    const [open, setOpen] = useState(false);
    const [languages, setLanguages] = useState([])
    const [tecnologies, setTecnologies] = useState([])
    const [profesionalProfile, setProfesionalProfile] = useState([])
    const [languagesSelected, setLanguagesSelected] = useState([])
    const [countries, setCountries] = useState([])
    const [availabilities, setAvailabilities] = useState([])
    const [tecnologiesSelected, setTecnologiesSelected] = useState([]);
    const [profesionalProfileSelected, setprofesionalProfileSelected] = useState([]);
    const [cv, setCv] = useState(null)
    const [file, setFile] = useState()
    const navigate = useNavigate();
    const {id} = useParams();
    const {handleSubmit, control, setValue, formState: {errors}} = useForm({mode: "onChange"});
    const [validateProfesionalProfile, setValidateProfesionalProfile] = useState(false)
    const [validateTecnology, setValidateTecnology] = useState(false)
    const [validateFile, setValidateFile] = useState(false)

    const handleChange = (event) => {
        const {target: {value, name},} = event;
        if (name === "Idioma") {
            setLanguagesSelected(typeof value === 'string' ? value.split(',') : value,);
        }
        if (name === "*Tecnología") {
            setTecnologiesSelected(typeof value === 'string' ? value.split(',') : value,);
            setValidateTecnology(false)
        }
        if (name === "*Perfil Profesional") {
            setprofesionalProfileSelected(typeof value === 'string' ? value.split(',') : value,);
            setValidateProfesionalProfile(false)
        }
    }

    useEffect(() => {
        setOpen(false)
        axios.get(`${url}contacts/pickup-fields-metadata`, ConfigHeaderMultiPart()).then(({data: {fields}}) => {
            fields.map((field) => {
                switch (field.apiName){
                    case "Pais_Residencia":
                        setCountries(field.pickListValues)
                        break
                    case "Disponibilidad_de_Ingreso":
                        setAvailabilities(field.pickListValues)
                        break
                    case "Perfil_Profesional":
                        setProfesionalProfile(field.pickListValues)
                        break
                    case "Tecnologias_Principales":
                        setTecnologies(field.pickListValues)
                        break
                    case "Idioma":
                        setLanguages(field.pickListValues)
                        break
                }
            })
            setOpen(false)
        }).then(() => {
            if (id !== undefined) {
                setOpen(true)
                axios.get(`${url}contacts/${id}`, ConfigHeaderMultiPart()).then(({data}) => {
                    setValue("name", data.name, {shouldDirty: true, shouldValidate: true})
                    setValue("lastname", data.lastname, {shouldDirty: true, shouldValidate: true})
                    setValue("countryOfResidence", data.countryOfResidence, {
                        shouldDirty: true,
                        shouldValidate: true
                    })
                    setValue("costContact", data.costContact, {shouldDirty: true, shouldValidate: true})
                    setValue("email", data.email)
                    setValue("dateOfBirth", parseISO(data.dateOfBirth))
                    setValue("availability", data.availability)
                    setValue("cellphone", data.cellphone)
                    setValue("extract", data.extract)
                    setValue("id", data.id)
                    setValue("workingExperienceInEnglish", data.workingExperienceInEnglish)
                    setValue("yearsOfExperience", data.yearsOfExperience)
                    setLanguagesSelected(data.language)
                    setTecnologiesSelected(data.techStack)
                    setprofesionalProfileSelected(data.professionalProfile)
                    setCv(data.resume)
                    setOpen(false)
                })
            }
        })
    }, []);

    const validateExtraField = () => {
        let next = false
        if (file === undefined) {
            if (id === undefined) {
                setValidateFile(true)
                next = true
            }
        }
        if (tecnologiesSelected.length === 0) {
            setValidateTecnology(true)
            next = true
        }
        if (profesionalProfileSelected.length === 0) {
            setValidateProfesionalProfile(true)
            next = true
        }

        return next
    }


    const onRegister = (e) => {
        let title = 'Registro De Candidatos'
        let subtitle = 'Realizado con exito'
        let comfirmationSubtitle = "Deseas guardar este registro ?"
        let textButtonComfirmation = "Registrar"
        if (id !== undefined) {
            title = 'Actualización De Candidato'
            subtitle = 'Realizada Con Exito'
            comfirmationSubtitle = "Deseas actualizar este registro ?"
            textButtonComfirmation = "Actualizar"
        }

        Swal.fire({
            title: title,
            text: comfirmationSubtitle,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: textButtonComfirmation,
            cancelButtonText: 'Cancelar'
        }).then((res) => {
            if (res.isConfirmed) {
                if (!validateExtraField()) {
                    setOpen(true)
                    e["language"] = languagesSelected
                    e["techStack"] = tecnologiesSelected
                    e["professionalProfile"] = profesionalProfileSelected
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append('contact',
                        new Blob([JSON.stringify(e)], {
                            type: 'application/json'
                        }));

                    if (id !== undefined) {
                        axios.patch(`${url}contacts/${id}`, formData, ConfigHeaderMultiPart())
                            .then((response) => {
                                if (response.status === 200) {
                                    setOpen(false)
                                    Swal.fire(
                                        title,
                                        subtitle,
                                        'success'
                                    ).then(r => navigate("/gestion-de-candidatos/candidatos"))
                                }
                            })
                            .catch(function (error) {
                                let message = ""
                                if (error.response.status === 400) {
                                    const errors = error.response.data.data.validationErrors
                                    if (errors.email) {
                                        message += `${errors.email[0]} , `
                                    }

                                    if (errors.professionalProfile) {
                                        message += `\n ${errors.professionalProfile[0]}`
                                    }

                                    if (errors.techStack) {
                                        message += `\n ${errors.techStack[0]}`
                                    }

                                    if (errors.file) {
                                        message += `\n ${errors.file[0]}`
                                    }
                                } else if (error.response.status === 500) {
                                    message = "Ha ocurrido un error interno"
                                }
                                setOpen(false)
                                Swal.fire(
                                    'Error',
                                    `${message}`,
                                    'error'
                                )
                            });
                    } else {
                        axios.post(`${url}contacts`, formData, ConfigHeaderMultiPart())
                            .then((response) => {
                                if (response.status === 201) {
                                    setOpen(false)
                                    Swal.fire(
                                        title,
                                        subtitle,
                                        'success'
                                    ).then(r => {
                                        if (localStorage.getItem('vacancy') !== null) {
                                            localStorage.setItem('candidateVacancy', JSON.stringify(response.data))
                                            navigate("/vacantes")
                                        } else {
                                            navigate("/gestion-de-candidatos/candidatos")
                                        }
                                    })
                                }
                            })
                            .catch(function (error) {
                                let message = ""
                                if (error.response.status === 400) {
                                    const errors = error.response.data.data.validationErrors
                                    if (errors.email) {
                                        message += `${errors.email[0]} , `
                                    }

                                    if (errors.professionalProfile) {
                                        message += `\n ${errors.professionalProfile[0]}`
                                    }

                                    if (errors.techStack) {
                                        message += `\n ${errors.techStack[0]} , `
                                    }

                                    if (errors.file) {
                                        message += `\n ${errors.file[0]}`
                                    }


                                } else if (error.response.status === 500) {
                                    message = "Ha ocurrido un error interno"
                                }
                                setOpen(false)
                                Swal.fire(
                                    'Error',
                                    `${message}`,
                                    'error'
                                )
                            });
                    }
                }
            }
        })
    }

    const handleChangeFile = e => {
        if (e.target.files.length) {
            const type = e.target.files[0].type
            if (type === "application/pdf" || type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                if (e.target.files[0].size < 3000000) {
                    setFile(e.target.files[0])
                    setValidateFile(false)
                } else {

                    Sweet.fire({
                        icon: 'error',
                        title: 'Información',
                        text: 'El archivo no debe exceder los 3 MB',
                        confirmButtonText: 'Si',
                        reverseButtons: true
                    }).then()
                }

            } else {
                Sweet.fire({
                    icon: 'error',
                    title: 'Información',
                    text: 'Solo debe ser formato (.pdf o .docx)',
                    confirmButtonText: 'Si',
                    reverseButtons: true
                }).then()
            }

        }
    };

    const onDownload = () => {
        axios.get(`${url}contacts/${id}/resume`, ConfigHeaderBlobResponse())
            .then((response) => {
                const link = document.createElement("a");
                link.target = "_blank";
                link.download = cv.fileName;
                link.href = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: response.headers["content-type"],
                    })
                );
                link.click();
            })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Loading open={open}/>
            <Card sx={{height: "100%"}}>
                <MDBox style={{margin: "2%"}}>
                    <MDBox pt={2} pb={2}>
                        <Typography variant="h4">
                            {id !== undefined ? 'Actualizar Candidato' : 'Registro de Candidato'}
                        </Typography>
                    </MDBox>
                    <MDBox py={4}>
                        <form onSubmit={handleSubmit(onRegister)}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h6"> Información Personal</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: true,
                                                maxLength: 50,
                                                pattern: {
                                                    value: /^([a-zA-Z ]+)$/,
                                                    message: "name is invalid"
                                                }
                                            }}
                                            render={({field}) => <MDInput error={errors.name != null} variant="outlined"
                                                                          type="text" label="*Nombre"
                                                                          fullWidth{...field} />}
                                        />
                                        {errors.name && errors.name.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                        {errors.name && errors.name.type === "maxLength" &&
                                        <span style={{fontSize: 12, color: "red"}}>Longitud no debe ser mayor a 50 caracteres</span>}
                                        {errors.name && errors.name.type === "pattern" &&
                                        <span style={{fontSize: 12, color: "red"}}>Debe contener solo texto</span>}
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <Controller
                                            name="lastname"
                                            defaultValue=""
                                            control={control}
                                            rules={{
                                                required: true,
                                                maxLength: 50,
                                                pattern: {
                                                    value: /^([a-zA-Z ]+)$/,
                                                    message: "lastname is invalid"
                                                }
                                            }}
                                            render={({field}) => <MDInput error={errors.lastname != null}
                                                                          variant="outlined"
                                                                          type="text" label="*Apellido"
                                                                          fullWidth{...field} />}
                                        />
                                        {errors.lastname && errors.lastname.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                        {errors.lastname && errors.lastname.type === "maxLength" &&
                                        <span style={{fontSize: 12, color: "red"}}>Longitud no debe ser mayor a 50 caracteres</span>}
                                        {errors.lastname && errors.lastname.type === "pattern" &&
                                        <span style={{fontSize: 12, color: "red"}}>Debe contener solo texto</span>}
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <Controller
                                            name="email"
                                            defaultValue=""
                                            control={control}
                                            rules={{
                                                required: true,
                                                maxLength: 50,
                                                pattern: {
                                                    value: /^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+)\.([a-zA-Z]{2,5})$/,
                                                    message: "email is invalid"
                                                }
                                            }}
                                            render={({field}) => <MDInput error={errors.email != null}
                                                                          variant="outlined"
                                                                          type="text" label="*Correo"
                                                                          fullWidth{...field} />}
                                        />
                                        {errors.email && errors.email.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                        {errors.email && errors.email.type === "maxLength" &&
                                        <span style={{fontSize: 12, color: "red"}}>Longitud no debe ser mayor a 50 caracteres</span>}
                                        {errors.email && errors.email.type === "pattern" &&
                                        <span style={{fontSize: 12, color: "red"}}>Formato es invalido</span>}
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <Controller
                                            name="cellphone"
                                            defaultValue=""
                                            control={control}
                                            rules={{
                                                // required: true,
                                                maxLength: 15
                                            }}
                                            render={({field}) => <MDInput error={errors.cellphone != null}
                                                                          variant="outlined"
                                                                          type="text" label="Telefono"
                                                                          fullWidth{...field} />}
                                        />
                                        {errors.cellphone && errors.cellphone.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                        {errors.cellphone && errors.cellphone.type === "maxLength" &&
                                        <span style={{fontSize: 12, color: "red"}}>Longitud no debe ser mayor a 15 caracteres</span>}
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <Controller
                                            name="countryOfResidence"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: true,
                                                maxLength: 50
                                            }}
                                            render={({field}) =>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Pais de
                                                        Residencia</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Age"
                                                        {...field}
                                                    >
                                                        {countries.map((country, index) => (
                                                            <MenuItem key={index}
                                                                      value={country}>{country}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            }
                                        />
                                        {errors.countryOfResidence && errors.countryOfResidence.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                        {errors.countryOfResidence && errors.countryOfResidence.type === "maxLength" &&
                                        <span style={{fontSize: 12, color: "red"}}>Longitud no debe ser mayor a 50 caracteres</span>}
                                    </MDBox>
                                </Grid>
                                {/*<Grid item xs={12} md={6} lg={4}>*/}
                                {/*    <MDBox mb={2}>*/}
                                {/*        <Controller*/}
                                {/*            name="dateOfBirth"*/}
                                {/*            control={control}*/}
                                {/*            rules={{required: true}}*/}
                                {/*            defaultValue={new Date(new Date().getFullYear() - 15, 11, 31)}*/}
                                {/*            render={({field}) => (*/}
                                {/*                <FormControl fullWidth>*/}
                                {/*                    <DesktopDatePicker*/}
                                {/*                        label="*Fecha de Nacimiento"*/}
                                {/*                        maxDate={new Date()}*/}
                                {/*                        minDate={new Date(1940, 11, 31)}*/}
                                {/*                        inputFormat="dd/MM/yyyy"*/}
                                {/*                        {...field}*/}
                                {/*                        renderInput={(params) => <TextField {...params} />}*/}
                                {/*                    />*/}
                                {/*                </FormControl>*/}
                                {/*            )}*/}
                                {/*        />*/}
                                {/*        {errors.dateOfBirth && errors.dateOfBirth.type === "required" &&*/}
                                {/*        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}*/}
                                {/*    </MDBox>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <Typography variant="h6"> Información Laboral</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <Controller
                                            name="yearsOfExperience"
                                            defaultValue=""
                                            control={control}
                                            rules={{
                                                // required: true,
                                                maxLength: 50
                                            }}
                                            render={({field}) => <MDInput error={errors.yearsOfExperience != null}
                                                                          variant="outlined"
                                                                          type="number" label="Años de Experiencia"
                                                                          fullWidth{...field} />}
                                        />
                                        {errors.yearsOfExperience && errors.yearsOfExperience.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                        {errors.yearsOfExperience && errors.yearsOfExperience.type === "maxLength" &&
                                        <span style={{fontSize: 12, color: "red"}}>Longitud no debe ser mayor a 50 caracteres</span>}
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <Controller
                                            name="availability"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                // required: true
                                            }}
                                            render={({field}) =>
                                                <FormControl fullWidth>
                                                    <InputLabel
                                                        id="demo-simple-select-label">Disponibilidad</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Age"
                                                        {...field}
                                                    >
                                                        {availabilities.map((availability, index) => (
                                                            <MenuItem key={index}
                                                                      value={availability}>{availability}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            }
                                        />
                                        {errors.availability && errors.availability.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <SelectMultiple inputName="*Perfil Profesional"
                                                        values={profesionalProfile} onChange={handleChange}
                                                        selectedValue={profesionalProfileSelected}/>
                                        {validateProfesionalProfile &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <SelectMultiple inputName="Idioma" values={languages}
                                                        onChange={handleChange} selectedValue={languagesSelected}/>
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <SelectMultiple inputName="*Tecnología" values={tecnologies}
                                                        onChange={handleChange} selectedValue={tecnologiesSelected}/>
                                        {validateTecnology &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <Controller
                                            name="extract"
                                            defaultValue=""
                                            control={control}
                                            rules={{
                                                required: true,
                                                maxLength: 250
                                            }}
                                            render={({field}) => <MDInput error={errors.extract != null}
                                                                          variant="outlined"
                                                                          type="text" label="*Resumen"
                                                                          fullWidth{...field} />}
                                        />
                                        {errors.extract && errors.extract.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                        {errors.extract && errors.extract.type === "maxLength" &&
                                        <span style={{fontSize: 12, color: "red"}}>Longitud no debe ser mayor a 250 caracteres</span>}
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2} ml={1}>
                                        <Controller
                                            name="workingExperienceInEnglish"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <FormControlLabel {...field} control={<Checkbox checked={field.value}/>}
                                                                  label="Experiencia laboral en ingles"/>
                                            )}
                                        />
                                        {errors.workingExperienceInEnglish && errors.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <UploadFile
                                            id="coverPage"
                                            nameInput="cover"
                                            onChangeFile={handleChangeFile}
                                            file={file}
                                            textButton="Subir Curriculum"
                                            fileLoaded="Curriculum Cargado"
                                            allowedFormat=".pdf,.docx"
                                        />
                                        {validateFile &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                        <span style={{fontSize: 12, color: "black"}}>El archivo debe tener un peso inferior a los 3 MB</span>
                                    </MDBox>
                                </Grid>
                                {id !== undefined && (
                                    cv !== null && (
                                        <Grid item xs={12} md={6} lg={4}>
                                            <MDBox mb={2}>
                                                <Button
                                                    startIcon={<Download/>}
                                                    fullWidth
                                                    onClick={onDownload}
                                                    size="large"
                                                    variant="contained"
                                                    style={{background: "black", color: "white", height: "30px"}}>Descargar
                                                    Curriculum</Button>
                                            </MDBox>
                                        </Grid>
                                    )
                                )}
                                <Grid item xs={12}>
                                    <Typography variant="h6"> Información Económica</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MDBox mb={2}>
                                        <Controller
                                            name="costContact"
                                            control={control}
                                            defaultValue="0"
                                            rules={{required: true}}
                                            render={({field}) => (
                                                <FormControl fullWidth>
                                                    <TextField
                                                        label="Costo del Candidato"
                                                        value="0"
                                                        fullWidth
                                                        {...field}
                                                        name="totalOverhead"
                                                        id="formatted-numberformat-input"
                                                        InputProps={{
                                                            inputComponent: NumberFormatCurrencySystem,
                                                        }}
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                        {errors.desiredRate && errors.desiredRate.type === "required" &&
                                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                    </MDBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction="row" justifyContent="flex-end"
                                  alignItems="flex-end">
                                <Grid item xs={12} md={6} lg={3}>
                                    <MDBox mb={2}>
                                        <MDButton variant="gradient" type="submit" color="info" fullWidth>
                                            {id !== undefined ? 'Actualizar' : 'Registrar'}
                                        </MDButton>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </form>
                    </MDBox>
                </MDBox>
            </Card>
        </DashboardLayout>
    )
}

export default CandidateRegisterComponent
