import React, {useEffect, useState} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import {format} from "date-fns";
import {Typography} from "@mui/material";

export default function TimelineComponent({activities}) {
    const [voidActitvities, setVoidActivities] = useState(true)
    useEffect(() => {
        if (activities.left === 0) {
            setVoidActivities(true)
        } else {
            setVoidActivities(false)
        }
    }, [activities])
    const style = (activity) => {
        if (activity.jobApplicationStatus !== null) {
            return {color: "#00A7E1"}
        }
        if (activity.phase !== null) {
            return {color: "#2BA84A"}
        }
    }
    return (
        <>
            <Timeline position="alternate">
                {!voidActitvities && activities.map((activity, index) => (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent color="text.secondary">
                            {format(new Date(activity.createdAt), "dd-MM-yyyy hh:mm aaa")}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color={activity.origin === "INTERNAL" ? "primary" : "success"}/>
                            <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineContent sx={style(activity)}>{activity.description}</TimelineContent>
                    </TimelineItem>
                ))}
                {voidActitvities && (
                    <Typography>
                        No hay actividades recientes
                    </Typography>
                )}
            </Timeline>
        </>
    );
}
