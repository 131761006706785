import React, {useState} from "react";

import {Hail, People} from "@mui/icons-material";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";

import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../examples/loading";
import MDBox from "../../components/MDBox";
import MenuCard from "../../examples/Cards/MenuCards";

function Dashboard() {
    const [open] = useState(false)

    return (
        <DashboardLayout>
            <Loading open={open}/>
            <DashboardNavbar/>
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                        <MDBox mb={1.5}>
                            <Link to="/gestion-de-candidatos/candidatos">
                                <MenuCard
                                    icon={People}
                                    title="Candidatos"
                                />
                            </Link>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                        <MDBox mb={1.5}>
                            <Link to="/gestion-de-candidatos/candidatos-enviados">
                                <MenuCard
                                    icon={Hail}
                                    title="Candidatos Enviados"
                                />
                            </Link>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Dashboard;
