import { useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../config";

const isAuth = () => {
    const navigate = useNavigate();
    let auth = localStorage.getItem("token")
    if (auth === "") {
        navigate("/")
    } else {
        axios.get(`${url}auth/validate/${auth.replaceAll("\"", "")}`).then((response) => {

        }).catch(error => {
            if (error.response.status === 403) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                localStorage.setItem("user", '')
                localStorage.setItem("token", '')
                navigate("/")
            }
        })
    }
}

const getRole = () => {
    let userLocal = localStorage.getItem("user")
    if (userLocal !== null) {
        let user = JSON.parse(userLocal)
        return user.roles[0]
    }
}

const validateRole = (roles, rol) => {
    return roles.includes(rol);
}

const roles = (rol, pathname, route) => {
    const navigate = useNavigate();
    const isPublic = rol.includes('PUBLIC');
    if (route === pathname) {
        if (!isPublic) {
            let userLocal = localStorage.getItem("user")
            let user = JSON.parse(userLocal)
            let userRole = user.roles[0];
            if (!rol.includes(userRole)) {
                navigate("/not-permission")
            }
        }
    }
}

const routePermissions = (key, route, pathname) => {
    const navigate = useNavigate();
    if (route === pathname && key === "invoices") {
		let sendInvoice = localStorage.getItem("sendInvoice")
		if (sendInvoice !== "true") {
			navigate("/dashboard")
		}
	}

}

export { isAuth, roles, getRole, validateRole, routePermissions }
