let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

let euroSpanish = Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
});

export {
    dollarUS, euroSpanish
}
