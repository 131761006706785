import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from "@mui/material/FormControl";
import {Controller, useForm} from "react-hook-form";
import Box from "@mui/material/Box";

import {Candidate} from "../../../utils/candidate";
import AutocompleteComponent from "./autocompleteComponent";

export default function DialogCandidateComponent({handleClose, open, onChange, candidateSelected, onRegister}) {
    const {control, formState: {errors}} = useForm();
    const candidates = Candidate()
    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Postular Candidatos</DialogTitle>
                <DialogContent>
                    <Box style={{marginTop: "5%"}}/>
                    <FormControl fullWidth>
                        <Controller
                            name="candidate"
                            control={control}
                            rules={{required: true}}
                            render={({field}) =>
                                (<AutocompleteComponent
                                    onChange={onChange}
                                    candidates={candidates}
                                    candidate={candidateSelected}
                                />)
                            }
                        />
                        {errors.candidate && errors.candidate.type === "required" &&
                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={onRegister}>Postular</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
