import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from "@mui/material/FormControl";
import {Controller, useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DialogContentText from "@mui/material/DialogContentText";

export default function DialogDeleteComponent({handleClose, open, onChangeDescription, description, onRegister}) {
    const {control, formState: {errors}} = useForm();

    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
                <DialogTitle>Eliminar Candidatos</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Introduce el motivo por el cual deseas eliminar este candidato
                    </DialogContentText>
                    <Box style={{marginTop: "5%"}}/>
                    <FormControl fullWidth>
                        <Controller
                            name="candidate"
                            control={control}
                            rules={{required: true}}
                            render={({field}) =>
                                ( <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Motivo"
                                    type="text"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={description}
                                    onChange={onChangeDescription}
                                    variant="standard"
                                />)
                            }
                        />
                        {errors.candidate && errors.candidate.type === "required" &&
                        <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={onRegister}>Eliminar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
