import {forwardRef} from "react";
import {
    AddBox, ArrowDownward,
    Check, ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage, LastPage, Remove,
    SaveAlt, Search, ViewColumn
} from "@mui/icons-material";
import * as React from "react";
import idLocale from 'date-fns/locale/es';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const localization = {
    pagination: {
        labelDisplayedRows: '{from}-{to} De {count}',
        nextTooltip: 'Pagina Siguiente',
        previousTooltip: 'Pagina Anterior',
        firstTooltip: 'Pagina Inicial',
        lastTooltip: 'Ultima Pagina',
        labelRowsSelect: 'Filas'
    },
    toolbar: {
        nRowsSelected: '{0} Fila(s) Seleccionadas',
        searchPlaceholder: 'Buscar',
        searchTooltip: 'Buscar'
    },
    header: {
        actions: 'Acción'
    },
    body: {
        emptyDataSourceMessage: 'No Hay Registros',
        filterRow: {
            filterTooltip: 'Filtrar'
        },
        editTooltip: 'Actualizar',
        deleteTooltip: 'Eliminar',
        addTooltip: 'Añadir',
        dateTimePickerLocalization: idLocale,
        editRow: {
            deleteText: 'Estas Seguro De Eliminar Este Registro?',
            saveTooltip: 'Guardar',
            cancelTooltip: 'Cancelar'
        }
    },
}

export {localization, tableIcons}
