import {Box, FormControl, Grid} from "@mui/material";
import {InputController,InputNumberFormatController, InputDatePickerController} from "../../../components/CustomInput"
import UploadFile from "../../../examples/UploadFile";
import React from "react";

export const ChargedAutomaticComponent = ({errors, control, file, handleChangeFile, validateFile}) => {
    return (
        <>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputController name="invoiceNumber" control={control}
                                     type="string"
                                     defaultValue="" label="Numero de factura"/>
                    {errors.invoiceNumber &&
                    <span
                        style={{fontSize: 12, color: "red"}}>{errors.invoiceNumber.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputNumberFormatController name="subtotal" control={control}
                                                 defaultValue="" label="Subtotal" />
                    {errors.subtotal &&
                    <span style={{fontSize: 12, color: "red"}}>{errors.subtotal.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputNumberFormatController name="itbmsTax" control={control}
                                                 defaultValue="" label="ITBMS"/>
                    {errors.itbmsTax &&
                    <span style={{fontSize: 12, color: "red"}}>{errors.itbmsTax.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputNumberFormatController name="total" control={control}
                                                 defaultValue="" label="Total"/>
                    {errors.total &&
                    <span style={{fontSize: 12, color: "red"}}>{errors.total.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputController name="ccEmail" control={control}
                                     type="email"
                                     defaultValue="" label="Correo de Copia"/>
                    {errors.ccEmail &&
                    <span style={{fontSize: 12, color: "red"}}>{errors.ccEmail.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputDatePickerController
                        control={control}
                        defaultValue={new Date()}
                        openTo='month'
                        name="invoiceDate"
                        label="Fecha de Factura"
                        views={['year', 'month', 'day']}
                    />
                    {errors.invoiceDate &&
                    <span style={{fontSize: 12, color: "red"}}>{errors.invoiceDate.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputController name="comments" control={control}
                                     type="string"
                                     defaultValue="" label="Comentario"/>
                    {errors.comments &&
                    <span
                        style={{fontSize: 12, color: "red"}}>{errors.comments.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box mb={2}>
                    <UploadFile
                        id="coverPage"
                        nameInput="cover"
                        onChangeFile={handleChangeFile}
                        file={file}
                        textButton="Cargar Factura"
                        fileLoaded="Factura Cargada"
                        allowedFormat=".pdf, .docx, .xlsx, .jpg, .jpeg, .png"
                    />
                    {validateFile.size &&
                    <span style={{fontSize: 12, color: "red"}}>El archivo no debe exceder los 3 MB</span>}
                    {validateFile.type &&
                    <span style={{fontSize: 12, color: "red"}}>Solo debe ser formato (.pdf o .docx)</span>}
                    {validateFile.required &&
                    <span style={{fontSize: 12, color: "red"}}>El campo es requerido</span>}
                </Box>
            </Grid>
        </>
    )
}
