import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutocompleteComponent({candidates, candidate, onChange}) {
    const options = candidates.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
        };
    });

    return (
        <Autocomplete
            multiple
            fullWidth
            value={candidate}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            id="tags-standard"
            onChange={(_event, newTeam) => {
                onChange(newTeam);
            }}
            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.name + " " + option.lastname}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="Candidatos"
                    placeholder="Selecciona los candidatos"
                />
            )}
        />
    );
}
