import { useEffect, useState } from "react";

import { format, parseISO } from "date-fns";
import { Chip, IconButton } from "@mui/material";
import { Cancel, Check, Download, Visibility, CancelScheduleSend, CoPresent, BrowserUpdated } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { CustomAxios, downloadFile, showFile } from "../../../components/custom/axiosCustom"
import MDBox from "../../../components/MDBox";
import { useLoading } from "../../../hooks/useLoading";
import { MuiTableComponent, Translation } from "../../../components/MuiTable";
import { Config } from "../../../utils/configHeader";
import { ErrorServer } from "../../../components/custom/SwalCustom";
import { dollarUS } from "../../../utils/moneyFormat";
import Loading from "../../../examples/loading";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";

export const VisualizerInvoicesComponent = () => {
    const {loading, thisLoading, notLoading } = useLoading()
    const [data, setData] = useState([])
    const navigate = useNavigate();

    const status = (value) => {
        switch (value) {
            case "Pagada":
                return <Chip label="Pagada" sx={{ backgroundColor: "#9c27b0", color: "white" }} />
            case "Pendiente de revisión":
                return <Chip icon={<Visibility />} label={value} />
            case "Rechazada":
                return <Chip icon={<CancelScheduleSend sx={{ color: "white" }} />} label={value} sx={{ backgroundColor: "#d47510", color: "white" }} />
            case "Aprobada":
                return <Chip icon={<Check sx={{ color: "white" }} />} label={value} sx={{ backgroundColor: "#05a724", color: "white" }} />
            case "Cancelada":
                return <Chip icon={<Cancel sx={{ color: "white" }} />} label={value} sx={{ backgroundColor: "#d42d10", color: "white" }} />
        }
    }

    const types = (value) => {
        switch (value) {
            case "Personal":
                return <Chip icon={<CoPresent />} label={value} sx={{ backgroundColor: "#7EB207", color: "white" }} />
            case "Digital":
                return <Chip icon={<BrowserUpdated sx={{ color: "white" }} />} label={value} sx={{ backgroundColor: "#0B9BC5", color: "white" }} />
        }
    }

    const getInvoiceSubmissions = () => {
        thisLoading()
        let email = localStorage.getItem('email')
        CustomAxios.get("invoice-submissions/"+email, Config()).then(({ data }) => {
            setData(data)
            notLoading()
        }).catch(() => {
            notLoading()
            ErrorServer().then()
        })
    }

    useEffect(() => {
        getInvoiceSubmissions();
    }, []);


    const onView = (index) => {
        if (data[index].invoice) {
            navigate(`/gestion-de-facturas/factura/ver/${data[index].invoice.id}`)
        } else {
            thisLoading()
            const url = `invoice-submissions/${data[index].id}/download`
            showFile(url)
                .catch(() => {
                    ErrorServer().then()
                })
                .finally(() => notLoading())
        }
    }

    const onDownload = (index) => {
        thisLoading()
        downloadFile(
            `invoice-submissions/${data[index].id}/download`,
            `Factura_Inicial_${localStorage.getItem('name')}_${format(new Date(), 'dd_MM_yyyy_HH:mm:ss')}.pdf`
        ).finally(() => notLoading())
    }

    const columns = [
        {
            name: "invoiceNumber",
            label: "Referencia",
        },
        {
            name: "status",
            label: "Estado",
            options: {
                customBodyRender: (value) => {
                    return status(value)
                }
            }
        },
        {
            name: "types",
            label: "Tipo",
            options: {
                customBodyRender: (value) => {
                    return types(value)
                }
            }
        },
        {
            name: "rejectReason",
            label: "Razón de Rechazo",
        },
        {
            name: "sentDate",
            label: "Fecha",
            options: {
                customBodyRender: (value) => {
                    return value === undefined ? '' : format(parseISO(value), 'dd/MM/yyyy')
                }
            }
        },
        {
            name: "total",
            label: "Total",
            options: {
                filter: true,
                customBodyRender: (value) => dollarUS.format(value),
            }
        },
        {
            name: "Ver",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() => onView(dataIndex)}>
                            <Visibility />
                        </IconButton>
                    );
                }
            }
        },
        {
            name: "Descargar",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() => onDownload(dataIndex)}>
                            <Download />
                        </IconButton>
                    );
                }
            }
        },
        /*{
            name: "Cancelar",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() => onCancelInvoice(dataIndex)}>
                            <Cancel/>
                        </IconButton>
                    );
                }
            }
        },*/
    ]

    const options = {
        search: true,
        download: true,
        print: false,
        viewColumns: true,
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        textLabels: Translation,
        tableBodyHeight: '400px',
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        selectableRowsHideCheckboxes: true,
    };

    return (
        <DashboardLayout>
            <Loading open={loading} />
            <DashboardNavbar />
            <MDBox py={3}>
                <MuiTableComponent data={data} title="Facturas Enviadas" options={options} columns={columns} />
            </MDBox>
        </DashboardLayout>
    )
}
