const routeWithAccent = [
    {
        key: "configuracion",
        value: "configuración"
    },
    {
        key: "provision",
        value: "provisión"
    }
]

const getRoute = (route) => {
    // routeWithAccent.map((e) => {
    //     if (e.key.toString() !== route.toString()) {} else {
    //         console.log(e.value)
    //         return e.value
    //     }
    // })
    switch (route){
        case "configuracion" :
            return "CONFIGURACIÓN"
        case "gestion-de-candidatos" :
            return "GESTIÓN DE CANDIDATOS"
        default:
            return route.replaceAll("-", " ").toUpperCase()

    }
}

export default getRoute
