import React, {useEffect, useState} from "react";

import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../examples/loading";
import MDBox from "../../components/MDBox";
import CardResourseComponent from "./component/cardResourseComponent";
import {Config} from "../../utils/configHeader";
import url from "../../config/index"


const ResourceView = () => {
    const [open, setOpen] = useState(false)
    const [vendorsFile, setVendorFile] = useState([])

    useEffect(() => {
        setOpen(true)
        axios.get(`${url}vendor-files/all`, Config()).then(({data}) => {
            setVendorFile(data)
            setOpen(false)
        })
    }, [])
    return (
        <DashboardLayout>
            <Loading open={open}/>
            <DashboardNavbar/>
            <MDBox py={3}>
                <Grid container spacing={3}>
                    {vendorsFile.length > 0 ? vendorsFile.map((file, index) => (
                        <Grid key={index} item xs={12} md={6} lg={4} xl={4}>
                            <CardResourseComponent title={file.title} url={file.link} typeIcon={file.type}/>
                        </Grid>
                    )) : (<>
                        <Grid item xs={12}>
                            <Box height="600px" display="flex" justifyContent="center" alignItems="center">
                                <p>No hay recursos disponibles</p>
                            </Box>
                        </Grid>
                    </>)}
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default ResourceView;
