/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";

function MenuCard({color, title, icon}) {
    const Icons = icon;
    return (
        <Card pt={1} px={2}>
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        {/*<MDBox display="flex" justifyContent="space-between" pt={1} px={2}>*/}
                        <MDBox
                            variant="gradient"
                            bgColor={color}
                            color={color === "light" ? "dark" : "white"}
                            borderRadius="xl"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="4rem"
                            height="4rem"
                        >
                            <Icons fontSize="medium" color="inherit"/>
                        </MDBox>
                    </Grid>
                    <Grid item xs={8}>
                        <MDBox textAlign="right" height="4rem" mt={1.2}>
                            <Typography style={{overflow: "hidden", textOverflow: "ellipsis"}} noWrap={false}
                                        variant="h5" fontWeight="light">
                                {title}
                            </Typography>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of ComplexStatisticsCard
MenuCard.defaultProps = {
    color: "info",
    percentage: {
        color: "success",
        text: "",
        label: "",
    },
};

// Typechecking props for the ComplexStatisticsCard
MenuCard.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    title: PropTypes.string.isRequired,
    percentage: PropTypes.shape({
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "white",
        ]),
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
    }),
};

export default MenuCard;
