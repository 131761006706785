import React, {useEffect, useState} from "react";

import axios from "axios";
import Grid from "@mui/material/Grid";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import {LoadingButton} from "@mui/lab";

import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../examples/loading";
import MDBox from "../../components/MDBox";
import CardJobsComponent from "./component/cardJobsComponent";
import url from "../../config";
import {Config, ConfigHeaderBlobResponse} from "../../utils/configHeader";
import PaginationComponent from "../../examples/Pagination";
import {SearchInputComponent} from "./component/searchInputComponent";
import {useForm} from "react-hook-form";

const perChunk = 5

function Dashboard() {
    const [open, setOpen] = useState(false)
    const [vancancies, setVacancies] = useState([])
    const [allVacancies, setAllVacancies] = useState([])
    const [allVacancy, setallVacancy] = useState([])
    const [controlledModalVacancy, setControlledModalVacancy] = useState(false)
    const [page, setPage] = useState(1);
    const [countPage, setCountPage] = useState(1)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const {control, watch, reset, formState: {errors}} = useForm({})
    useParams();

    const search = watch("search")

    const createPaginate = (data) => {
        let result = paginateArray(data)
        setPage(1)
        setVacancies(result[0])
        setAllVacancies(result)
        setCountPage(result.length)
        setOpen(false)
    }

    const onSearch = () => {
        setOpen(true)
        let data = allVacancy.filter(vacancy => vacancy.name.toUpperCase().includes(search.toUpperCase()))
        createPaginate(data)
    }

    const onResetSearch = () => {
        setOpen(true)
        reset({
            search: ""
        })
        createPaginate(allVacancy)
    }

    const handleChange = (event, value) => {
        setOpen(true)
        setVacancies(allVacancies[value - 1])
        setPage(value);
        setOpen(false)
    };

    const paginateArray = (array) => {
        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)
            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []
            }
            resultArray[chunkIndex].push(item)
            return resultArray
        }, [])
    }

    const onDownload = () => {
        if (!isButtonLoading) {
            setIsButtonLoading(true)
            axios.get(`${url}vacancies/download`, ConfigHeaderBlobResponse())
                .then(({data, headers}) => {
                    const link = document.createElement("a");
                    link.target = "_blank";
                    link.download = 'vacantes.xlsx';
                    link.href = window.URL.createObjectURL(
                        new Blob([data], {
                            type: headers["content-type"],
                        })
                    );
                    link.click();
                    setIsButtonLoading(false)
                })
        }
    }

    useEffect(() => {
        setOpen(true)
        axios.get(`${url}vacancies/all`, Config()).then(({data}) => {
            setallVacancy(data)
            createPaginate(data)
            setControlledModalVacancy(true)
        }).catch(error => setOpen(false))
    }, [])

    useEffect(() => {
        if (controlledModalVacancy) {
            if (localStorage.getItem('vacancy') !== null && localStorage.getItem('candidateVacancy') !== null) {
                let vacancy = JSON.parse(localStorage.getItem('vacancy'))
                let candidate = JSON.parse(localStorage.getItem('candidateVacancy'))
                Swal.fire({
                    title: "Postular Candidato",
                    html: `¿Desea postular al candidato ${candidate.name} ${candidate.lastname} <br> A la vacante ${vacancy.name}?`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Postular',
                    cancelButtonText: 'Cancelar'
                }).then((res) => {
                    setOpen(true)
                    if (res.isConfirmed) {
                        let candidateId = []
                        candidateId.push(candidate.id)
                        axios.post(`${url}vacancies/${vacancy.id}/apply/`, candidateId, Config()).then((response) => {
                            setOpen(false)
                            Swal.fire(
                                "Postulación",
                                "Registrada con exito",
                                'success'
                            ).then(r => {
                                localStorage.removeItem('vacancy')
                                localStorage.removeItem('candidateVacancy')
                            })
                        }).catch(({response: {status, data}}) => {
                            if (status === 400) {
                                let message = ""
                                const errors = data.data.validationErrors
                                if (errors.contactIds) {
                                    message = errors.contactIds[0]
                                }

                                setOpen(false)
                                Swal.fire(
                                    'Error',
                                    `${message}`,
                                    'error'
                                )
                            }
                        })
                    } else {
                        setOpen(false)
                        localStorage.removeItem('vacancy')
                        localStorage.removeItem('candidateVacancy')
                    }
                })
            }
        }
    }, [controlledModalVacancy])

    return (
        <DashboardLayout>
            <Loading open={open}/>
            <DashboardNavbar/>
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box display="flex" justifyContent="start" bottom="0px">
                            <SearchInputComponent control={control} onSearch={onSearch} onResetSearch={onResetSearch}/>
                        </Box>
                    </Grid>
                    <Grid item md={2} lg={3} xl={3}/>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Box display="flex" justifyContent="end" bottom="0px">
                            <LoadingButton
                                style={{backgroundColor: "black", color: "white"}}
                                onClick={onDownload}
                                loading={isButtonLoading}
                                loadingIndicator="Loading..."
                                variant="contained"
                                fullWidth
                            >
                                {isButtonLoading ? "Descargando..." : "Exportar vacantes a excel"}
                            </LoadingButton>
                        </Box>
                    </Grid>
                    {allVacancies.length > 0 ? (
                        <>
                            <Grid item xs={12}>
                                {vancancies.map((vacancy, index) => (
                                    <CardJobsComponent key={index} vacancy={vacancy}/>
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" bottom="0px">
                                    <PaginationComponent page={page} count={countPage} onChangePage={handleChange}/>
                                </Box>
                            </Grid>
                        </>
                    ) : (<>
                        <Grid item xs={12}>
                            <Box height="600px" display="flex" justifyContent="center" alignItems="center">
                                <p>No hay vacantes disponibles</p>
                            </Box>
                        </Grid>
                    </>)}

                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Dashboard;
