import {useEffect, useState} from "react";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {Routes, Route, Navigate, useLocation, useNavigate} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {es} from "date-fns/locale";
import {useIdleTimer} from 'react-idle-timer'
import Swal from "sweetalert2";

import Sidenav from "./examples/Sidenav";
import theme from "./assets/theme";
import themeDark from "./assets/theme-dark";
import routes from "./routes";
import routesSidenav from "./routesSidenav";
import {useMaterialUIController, setMiniSidenav} from "./context/index";
import brandWhite from "./assets/images/logo-ct.png";
import brandDark from "./assets/images/logos/logo.svg";
import {routePermissions} from "./utils/isAuth";

export default function App() {

    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const {pathname} = useLocation();
    const timeout = 600000
    const [isIdle, setIsIdle] = useState(false)
    const handleOnActive = () => setIsIdle(false)
    const handleOnIdle = () => setIsIdle(true)
    const navigate = useNavigate();
    const {resume} = useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle
    })
    const [sidenavRoutes, setSidenavRoutes] = useState([]);

    let auth = localStorage.getItem("token")

   let providerLite = localStorage.getItem("providerLite")

    useEffect(() => {
        let validatedRoutes;
        if (providerLite === 'true') {
            validatedRoutes = routesSidenav.filter(item => item.name !== 'Vacantes')
            setSidenavRoutes(validatedRoutes)
        } else {
            setSidenavRoutes(routesSidenav)
        }
    }, [pathname])

    useEffect(() => {
            if (auth !== "") {
                if (isIdle) {
                    localStorage.removeItem("user");
                    localStorage.removeItem("token");
                    Swal.fire({
                        title: 'Inactividad',
                        text: "Se ha comprobado inactividad por más de 10 minutos en la aplicación, por su seguridad y la nuestra la sesión será cerrada ",
                        icon: 'info',
                    }).then((res) => {
                        navigate("/logout");
                    })
                }
            }
        }, [isIdle]
    )

    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }
            if (route.route) {
                routePermissions(route.key, route.route, pathname)
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <ThemeProvider theme={darkMode ? themeDark : theme}>
                <CssBaseline/>
                {layout === "dashboard" && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandWhite : brandDark}
                            brandName="Material Dashboard 2"
                            routes={sidenavRoutes}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                    </>
                )}
                <Routes>
                    {getRoutes(routes)}
                    <Route path="*" element={<Navigate to="/dashboard"/>}/>
                </Routes>
            </ThemeProvider>
        </LocalizationProvider>
    );
}
