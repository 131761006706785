/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, HashRouter} from "react-router-dom";
import App from "./App";

// Soft UI Context Provider
import {MaterialUIControllerProvider} from "./context/index";

ReactDOM.render(
    <HashRouter>
        <MaterialUIControllerProvider>
            <App/>
        </MaterialUIControllerProvider>
    </HashRouter>,
    document.getElementById("root")
);
