import React, { useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom'
import axios from "axios";
import url from "./config";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const location = useLocation();
    useEffect(() => {
        let auth = localStorage.getItem("token");
        if(auth){
            axios.get(`${url}auth/validate/${auth.replaceAll("\"", "")}`)
                .then((response) => {
                    setIsAuthenticated(true);
                })
                .catch(error => {
                    if(error.response.status === 403){
                        localStorage.removeItem("user");
                        localStorage.removeItem("token");
                        localStorage.setItem("user", '')
                        localStorage.setItem("token", '')
                        setIsAuthenticated(false)
                    }
                })
        } else {
            setIsAuthenticated(false)
        }
    }, [location])

    if(isAuthenticated === null){
        return <></>
    }

    return (
            !isAuthenticated ? (
                <Navigate to='/'/>
            ) : (
                children
            )
    );
};

export default PrivateRoute;