import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Loading from "../../examples/loading";
import MDBox from "../../components/MDBox";
import { ItemInvoiceStandartComponent } from "./component/itemInvoiceComponent";
import { useLoading } from "../../hooks/useLoading";


function InvoiceManagement() {
    const { loading, thisLoading, notLoading } = useLoading();

    return (
        <DashboardLayout>
            <Loading open={loading} />
            <DashboardNavbar />
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <ItemInvoiceStandartComponent
                        notLoading={notLoading}
                        thisLoading={thisLoading}
                    />
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default InvoiceManagement;
